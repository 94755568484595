import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import {
  CredentialIssuedDetailsView,
  CredentialListView,
} from '../../../../domain/credentialView';
import '../CredentialDetailsModal.css';
import closeIcon from '../../../../assets/closeIcon.png';
import {
  getCredentials,
  ParsedBackendError,
  revokeVerifiableCredential,
} from '../../../../services/vidcredentials';
import { Popup } from '../../../../components/Popup/Popup';
import { CredentialsContext } from '../../../../contexts/CredentialsContext';
import { I18n } from '../../../../i18n/i18n';
import { formatDate, transformCamelCase } from '../helpers/StringHelpers';
import { renderChildren } from '../helpers/RenderFunctions';

interface Props {
  credential: CredentialIssuedDetailsView;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const CredentialIssuedModal = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [wantsToRemove, setWantsToRemove] = useState(false);
  const [requestedRevocation, setRequestedRevocation] = useState(false);
  const { setCredentials } = useContext(CredentialsContext);

  const loadTable = () => {
    const loadCredentials = async () => {
      const data = await getCredentials();
      setCredentials(data as CredentialListView[]);
      return data;
    };
    loadCredentials();
  };

  const closeModal = (e: any) => {
    e.preventDefault();
    props.setIsOpen(false);
  };

  const revokeCredential = async () => {
    try {
      await revokeVerifiableCredential(props.credential.id);
      loadTable();
      setRequestedRevocation(true);
      setSuccessMessage(I18n.t('details.revokeSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }

    setWantsToRemove(false);
  };

  return (
    <Modal isOpen={props.isOpen} centered={true} fullscreen="lg" size="lg">
      <ModalHeader
        close={
          <a href="#" onClick={(e) => closeModal(e)}>
            <img src={closeIcon} className="iconClose" />
          </a>
        }
        toggle={() => props.setIsOpen(false)}
        className="modalHeader"
      >
        {props.credential.types?.includes('StudioCredential')
          ? I18n.t('details.userDetails')
          : I18n.t('details.credentialDetails')}
      </ModalHeader>
      <ModalBody className="modalBody">
        <table className="tableModal">
          <thead>
            <tr>
              <td style={{ backgroundColor: 'white' }}>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{I18n.t('details.credentialIssuanceId')}:</th>
              <td data-testid="type">{props.credential.issuanceId}</td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('details.type')}:</th>
              <td data-testid="type">{props.credential.types}</td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('details.issuer')}:</th>
              <td>{props.credential.issuer}</td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('details.holder')}:</th>
              <td data-testid="holder">
                {props.credential.credentialOwnerDid}
              </td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('table.status')}:</th>
              <td data-testid="status">
                {I18n.t(`status.${props.credential.status}`)}
              </td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('table.expirationDate')}:</th>
              <td data-testid="expirationDate">
                {formatDate(props.credential.expirationDate)}
              </td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('details.createdAt')}:</th>
              <td data-testid="created-at">
                {formatDate(props.credential.createdAt)}
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'white' }}>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        {props.credential.verifiableCredential &&
          Object.entries(props.credential.verifiableCredential).map(
            ([key, value]) => {
              return (
                <>
                  <h2 className="attributeHeader">{transformCamelCase(key)}</h2>
                  {renderChildren(value, {})}
                  <tr>&nbsp;</tr>
                </>
              );
            },
          )}
      </ModalBody>
      <ModalFooter>
        <div className="buttons-container">
          {props.credential.status === 'ISSUED' && !requestedRevocation && (
            <Button
              className="buttonRevoke"
              onClick={() => setWantsToRemove(true)}
            >
              {I18n.t('actions.revoke')}
            </Button>
          )}
        </div>
        {errorMessage && (
          <Popup
            error
            message={errorMessage}
            onClose={() => setErrorMessage('')}
          />
        )}
        {successMessage && (
          <Popup
            success
            message={successMessage}
            onClose={() => setSuccessMessage('')}
          />
        )}
        {wantsToRemove && (
          <Popup
            confirmation
            message={I18n.t('table.sureRevoke')}
            onConfirm={() => revokeCredential()}
            onReject={() => setWantsToRemove(false)}
            onClose={() => setWantsToRemove(false)}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
