import React from 'react';
import './ErrorMessage.css';

interface Props {
  messageTitle: string;
  message: string;
}

export const ErrorMessage = (props: Props) => {
  return (
    <>
      <h1>{props.messageTitle}</h1>
      <p id="errorMessage">{props.message}</p>
    </>
  );
};
