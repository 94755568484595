import { decodeJWT } from 'did-jwt';

export class UserAuth {
  constructor(private accessToken: string) {}

  getUserName(): string {
    const decodedToken = decodeJWT(this.accessToken);
    return decodedToken.payload.userInfo.name.toString();
  }

  getEntityName(): string {
    const decodedToken = decodeJWT(this.accessToken);
    return decodedToken.payload.userInfo.entityName.toString();
  }

  isAdmin(): boolean {
    const decodedToken = decodeJWT(this.accessToken);
    return decodedToken.payload.userInfo.role.toString() === 'admin';
  }
  isSuperadmin(): boolean {
    const decodedToken = decodeJWT(this.accessToken);
    return decodedToken.payload.userInfo.role.toString() === 'superadmin';
  }
  isOperator(): boolean {
    const decodedToken = decodeJWT(this.accessToken);
    return decodedToken.payload.userInfo.role.toString() === 'operator';
  }
  getRole(): string {
    const decodedToken = decodeJWT(this.accessToken);
    return decodedToken.payload.userInfo.role.toString();
  }
}
