import React from 'react';
import './Avatar.css';
import userIcon from '../../assets/userIcon.png';

interface Props {
  userName: string;
  entityName: string;
  role: string;
}

const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const Avatar = (props: Props) => {
  return (
    <>
      <img src={userIcon} className="iconAvatar" />
      <div className="content_right">
        <h6>
          <b>{props.userName}</b>
        </h6>
        <p className="subtitleAvatar">{props.entityName}</p>
        <p className="subtitleAvatar">{capitalize(props.role)}</p>
      </div>
    </>
  );
};
