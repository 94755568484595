import { Fragment, useContext, useEffect, useState } from 'react';
import { Button, Input, InputGroup } from 'reactstrap';
import { AuthContext } from '../../../contexts/AuthContext';
import { UserCredentialsContext } from '../../../contexts/UserCredentialsContext';
import { Entity } from '../../../domain/entity';
import { UserAuth } from '../../../domain/userAuth';
import { getEntities } from '../../../services/entities';
import './AdvancedFilterModal.css';

import { I18n } from '../../../i18n/i18n';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function AdvancedFilterModal(props: Props) {
  const { filters, setFilters, getCredentialsByFilters } = useContext(
    UserCredentialsContext,
  );
  const [entities, setEntities] = useState<Entity[]>([]);

  const { accessToken } = useContext(AuthContext);

  let isSuperadmin = false;

  if (accessToken) {
    const user = new UserAuth(accessToken);

    isSuperadmin = user.isSuperadmin();
  }

  const handleClose = () => {
    setFilters({
      entityId: '',
      entityName: '',
      did: '',
      email: '',
      userName: '',
      status: '',
      searchableText: '',
    });
    props.setIsOpen(false);
  };

  const applyFilter = () => {
    getCredentialsByFilters();
    props.setIsOpen(false);
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;

    if (name === 'entityId') {
      const selectedEntity = entities.find(
        (entity: Entity) => entity.id === value,
      );
      filters.entityName = selectedEntity?.name || '';
    }

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const fetchEntities = async () => {
    const user = new UserAuth(accessToken);

    isSuperadmin = user.isSuperadmin();

    if (isSuperadmin) {
      const entitiesResponse = await getEntities();

      setEntities(entitiesResponse as Entity[]);
    }
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return (
    <Fragment>
      {isSuperadmin && (
        <InputGroup className="input-finder-advanced-search-bar">
          <Input
            placeholder={I18n.t('filters.entityName')}
            name="entityId"
            value={filters.entityId}
            onChange={handleChange}
            type="select"
          >
            <option>{I18n.t('filters.selectEntity')}</option>
            {entities.map((entity: Entity, i: number) => {
              return (
                <option key={i} value={entity.id}>
                  {entity.name}
                </option>
              );
            })}
          </Input>
        </InputGroup>
      )}
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          id="exampleSelect"
          placeholder={I18n.t('table.status')}
          name="status"
          value={filters.status}
          type="select"
          onChange={handleChange}
        >
          <option value="">-- {I18n.t('table.status')} --</option>
          <option value="CREATED">{I18n.t('status.CREATED')}</option>
          <option value="ISSUED">{I18n.t('status.ISSUED')}</option>
          <option value="REVOKED">{I18n.t('status.REVOKED')}</option>
          <option value="REVOCATION_REQUESTED">
            {I18n.t('status.REVOCATION_REQUESTED')}
          </option>
          <option value="ISSUE_REQUESTED">
            {I18n.t('status.ISSUE_REQUESTED')}
          </option>
          <option value="REVOKED_FAILED">
            {I18n.t('status.REVOKED_FAILED')}
          </option>
        </Input>
      </InputGroup>
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          placeholder="Email"
          name="email"
          value={filters.email}
          onChange={handleChange}
        />{' '}
      </InputGroup>
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          placeholder="Name"
          name="userName"
          value={filters.userName}
          onChange={handleChange}
        />{' '}
      </InputGroup>
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          placeholder="DID"
          name="did"
          value={filters.did}
          onChange={handleChange}
        />{' '}
      </InputGroup>

      <Button
        className="buttonCancel"
        variant="secondary"
        onClick={handleClose}
      >
        {I18n.t('actions.cancel')}
      </Button>
      <Button
        className="buttonFilter"
        variant="secondary"
        onClick={applyFilter}
      >
        {I18n.t('actions.filter')}
      </Button>
    </Fragment>
  );
}
