import { I18n } from 'i18n-js';
import en from './locales/en';
import es from './locales/es';

const browserLang = navigator.language;
let initialLocale = 'en';

if (browserLang.includes('es')) initialLocale = 'es';
if (browserLang.includes('en')) initialLocale = 'en';

const i18n = new I18n(
  { en, es },
  {
    defaultLocale: localStorage.getItem('locale') || initialLocale,
    locale: localStorage.getItem('locale') || initialLocale,
    enableFallback: true,
  },
);

const changeLanguage = (lang: string) => {
  if (i18n.translations[lang] !== null) {
    i18n.locale = lang;
    localStorage.setItem('locale', lang);
  }
};

export { i18n as I18n, changeLanguage };
