import React from 'react';
import './FilterTag.css';

import closeIcon from '../../assets/closeIcon.png';

interface Props {
  filter: string;
  onClose: () => void;
}

const FilterTag = (props: Props) => {
  const { filter, onClose } = props;

  return (
    <div className="rectangle">
      <span>
        <b>{filter}</b>
      </span>
      {onClose && (
        <img className="clear-icon" src={closeIcon} alt="" onClick={onClose} />
      )}
    </div>
  );
};

export default FilterTag;
