// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { JSO } from 'jso';
import { OPENID } from '../config';

export class OpenIDClient {
  private static _instance: OpenIDClient;
  private readonly client: JSO;
  private readonly clienLogint: JSO;

  private constructor() {
    const configFile = {
      client_id: OPENID.CLIENT_ID,
      token: OPENID.IDENTITY_PROVIDER + '/oauth2/token',
      authorization: OPENID.IDENTITY_PROVIDER + '/oauth2/auth',
      redirect_uri: OPENID.REDIRECT_CALLBACK,
      response_type: 'code',
      debug: true,
      request: '324234324',
    };
    this.client = new JSO(configFile);

    const configFileLogin = {
      client_id: OPENID.CLIENT_ID,
      token: OPENID.IDENTITY_PROVIDER + '/oauth2/token',
      authorization: OPENID.IDENTITY_PROVIDER + '/oauth2/auth',
      redirect_uri: OPENID.REDIRECT_CALLBACK_LOGIN,
      response_type: 'code',
      debug: true,
      request: '324234324',
    };
    this.clienLogint = new JSO(configFileLogin);
  }

  public static getInstance(): OpenIDClient {
    return this._instance || (this._instance = new this());
  }

  public getClient(): JSO {
    return this.client;
  }

  public getLoginClient(): JSO {
    return this.clienLogint;
  }
}
