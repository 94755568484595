import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { LateralMenu } from '../../components/LateralMenu/LateralMenu';
import './Home.css';
import { HeaderHome } from '../../components/HeaderHome/HeaderHome';
import { MenuContextProvider } from '../../contexts/MenuContext';
import { TOKEN_REFRESH_TIME } from '../../domain/refreshToken';
import { Outlet } from 'react-router-dom';
import { useRefreshToken } from '../../hooks/useRefreshToken';

export function Home() {
  const tokenProcess = useRefreshToken();

  useEffect(() => {
    const id = setInterval(() => {
      tokenProcess.refreshTokenProcess();
    }, TOKEN_REFRESH_TIME);

    localStorage.setItem('intervalId', id as unknown as string);
  }, []);

  return (
    <MenuContextProvider>
      <Container className="containerParent" fluid>
        <HeaderHome />
        <Row className="rowContent">
          <LateralMenu />
          <Row className="containerChild">
            <Outlet />
          </Row>
        </Row>
      </Container>
    </MenuContextProvider>
  );
}
