import axios from 'axios';
import { FrontendErrors } from '../exceptions/frontendErrors';
import { BACKEND_URL, BACKEND_URL_BASE, OPENID, PATHS } from '../config';
import {
  CreateCredentialOrderDto,
  CreateUserCredentialOrderDto,
} from '../domain/createCredentialOrderDto';
import {
  CredentialDetailsView,
  CredentialListView,
  CredentialOrderDetailsView,
  CredentialRequestDto,
  CredentialRequestsListView,
  UserCredentialListView,
} from '../domain/credentialView';
import { I18n } from './../i18n/i18n';
import { RefreshTokenRequestDto } from '../domain/refreshToken';
import axiosInstance from './axiosInstance';
import { AuditTrailDetail, AuditTrailsList } from '../domain/auditTrails';
import { Device } from '../domain/devices';
import { DocumentStatusDTO } from '../domain/document';
import { CredentialRequestDetailsView } from '../domain/credentialRequestView';

export interface BackendError {
  statusCode: number;
  internalCode: string;
  message: string;
  timestamp: string;
}

export interface ParsedBackendError {
  title: string;
  details?: string;
}

export interface BackendResponse {
  data?: unknown;
  error?: ParsedBackendError;
}

function translateBackendError(error: BackendError): ParsedBackendError {
  console.log(error, error.message);
  const parsedError = {
    title: I18n.t(error.internalCode),
    details: error.message.split(']')[1],
  };
  return parsedError;
}

async function authLogin(code: string) {
  try {
    const body = {
      code: code,
      redirectUri: OPENID.REDIRECT_CALLBACK_LOGIN,
    };
    const response = await axiosInstance.post(
      `${BACKEND_URL}${PATHS.LOGIN}`,
      body,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.LOGIN_AUTH} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function refreshToken() {
  try {
    const body: RefreshTokenRequestDto = {
      grant_type: 'refresh_token',
    };

    const response = await axiosInstance.post(
      `${BACKEND_URL}${PATHS.TOKEN}`,
      body,
    );

    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function logout() {
  try {
    await axiosInstance.delete(`${BACKEND_URL}${PATHS.TOKEN}`);

    return true;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

function createCredentialOrder(
  credentialOrder: CreateCredentialOrderDto,
  language: string,
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(
        `${BACKEND_URL}${PATHS.CREDENTIAL_ORDER}?language=${language}`,
        credentialOrder,
      );
      if (response.status === 200 || response.status === 201) {
        return resolve(response.data);
      }
      if (axios.isAxiosError(response)) {
        const parsedError = translateBackendError(
          response.response?.data as BackendError,
        );
        reject(parsedError);
      }
      reject({ title: 'Unknown' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const parsedError = translateBackendError(
          error.response?.data as BackendError,
        );
        reject(parsedError);
      }
      reject({ title: 'Unknown' });
    }
  });
}

function createUserCredentialOrder(
  userCredentialOrder: CreateUserCredentialOrderDto,
  language: string,
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(
        `${BACKEND_URL}${PATHS.USER_CREATE_CREDENTIAL}?language=${language}`,
        userCredentialOrder,
      );
      if (response.status === 200 || response.status === 201) {
        return resolve(response.data);
      }
      if (axios.isAxiosError(response)) {
        const parsedError = translateBackendError(
          response.response?.data as BackendError,
        );
        reject(parsedError);
      }
      reject({ title: 'Unknown' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const parsedError = translateBackendError(
          error.response?.data as BackendError,
        );
        reject(parsedError);
      }
      reject({ title: 'Unknown' });
    }
  });
}

async function issueCredential(
  credentialOrderId: string,
  code: string,
): Promise<BackendResponse> {
  try {
    const body = { code: code, redirectUri: OPENID.REDIRECT_CALLBACK };
    const url = `${BACKEND_URL}${PATHS.ISSUE_CREDENTIAL}/${credentialOrderId}`;
    const response = await axios.post(url, body);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}
async function updateCredentialRequest(
  credentialOrderId: string,
  body: CredentialRequestDto,
): Promise<BackendResponse> {
  try {
    const url = `${BACKEND_URL}${PATHS.CREDENTIAL_REQUESTS}/${credentialOrderId}`;
    const response = await axiosInstance.put(url, body);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function rejectVerifiableCredential(credentialOrderId: string) {
  try {
    const url = `${BACKEND_URL}${PATHS.CREDENTIAL_REQUESTS}/${credentialOrderId}`;
    const response = await axiosInstance.delete(url);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function revokeVerifiableCredential(credentialId: string) {
  try {
    const url = `${BACKEND_URL}/revocation/${credentialId}`;
    const response = await axiosInstance.post(url);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function revokeUserVerifiableCredential(revocationPath: string) {
  try {
    const url = `${BACKEND_URL_BASE}${revocationPath}`;
    const response = await axiosInstance.post(url);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function getCredentialTypes() {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_TYPES}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_TYPES} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getCredentials(): Promise<
  CredentialListView[] | BackendResponse
> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_PROJECTIONS}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data as CredentialListView[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function getUserCredentials(): Promise<
  UserCredentialListView[] | BackendResponse
> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.USER_CREDENTIALS}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data as UserCredentialListView[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function getCredentialRequests(): Promise<
  CredentialRequestsListView[] | BackendResponse
> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_REQUESTS}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data as CredentialRequestsListView[];
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function checkDocumentStatus(
  credentialId: string,
): Promise<DocumentStatusDTO | BackendResponse> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.DOCUMENT_STATUS}/${credentialId}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data as DocumentStatusDTO;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function getFilteredCredentials(
  query: string,
): Promise<CredentialListView | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_PROJECTIONS}?${query}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data as CredentialListView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getFilteredUserCredentials(
  query: string,
): Promise<UserCredentialListView | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.USER_CREDENTIALS}?${query}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data as UserCredentialListView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getFilteredCredentialRequests(
  query: string,
): Promise<CredentialRequestsListView | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_REQUESTS}?${query}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data as CredentialRequestsListView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getAuditTrails(): Promise<
  AuditTrailsList[] | ParsedBackendError
> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.AUDIT_TRAILS}`,
    );

    return response.data as AuditTrailsList[];
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getDevicesList(): Promise<Device[] | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.DEVICE_LIST}`,
    );

    return response.data as Device[];
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getFilteredAuditTrails(
  query: string,
): Promise<any[] | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.AUDIT_TRAILS}?${query}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data as any[];
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getAuditTrailsDetail(
  eventId: string,
): Promise<AuditTrailDetail | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.AUDIT_TRAILS}/${eventId}`,
    );

    return response.data as AuditTrailDetail;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getCredentialView(
  detailsPath: string,
): Promise<
  CredentialDetailsView | CredentialOrderDetailsView | ParsedBackendError
> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL_BASE}${detailsPath}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_ORDER} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getCredentialRequest(
  credentialId: string,
): Promise<CredentialRequestDetailsView | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_REQUESTS}/${credentialId}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_REQUEST} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getUserCredential(
  detailsPath: string,
): Promise<CredentialDetailsView | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL_BASE}${detailsPath}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_ORDER} ${response.data}.`);
    }
    return response.data as CredentialDetailsView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getIvmTypeByCredentialRequestId(credentialId: string) {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_ORDER}/${credentialId}/ivm`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(
        `${FrontendErrors.LEVEL_OF_ASSURANCE_TYPE} ${response.data}.`,
      );
    }
    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function resendEmailCredentialOrder(
  resendPath: string,
): Promise<CredentialDetailsView | ParsedBackendError> {
  try {
    const response = await axiosInstance.post(
      `${BACKEND_URL_BASE}${resendPath}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.RESEND_EMAIL} ${response.data}.`);
    }
    return response.data as CredentialDetailsView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function resendEmailUserCredentialOrder(
  resendPath: string,
): Promise<CredentialDetailsView | ParsedBackendError> {
  try {
    const response = await axiosInstance.post(
      `${BACKEND_URL_BASE}${resendPath}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.RESEND_EMAIL} ${response.data}.`);
    }
    return response.data as CredentialDetailsView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}
export {
  authLogin,
  createCredentialOrder,
  getCredentials,
  issueCredential,
  rejectVerifiableCredential,
  revokeVerifiableCredential,
  getCredentialTypes,
  getCredentialView,
  getFilteredCredentials,
  getIvmTypeByCredentialRequestId as getLoATypeByCredentialRequestId,
  getUserCredentials,
  getUserCredential,
  createUserCredentialOrder,
  refreshToken,
  resendEmailCredentialOrder,
  logout,
  getAuditTrails,
  getAuditTrailsDetail,
  getFilteredAuditTrails,
  getFilteredUserCredentials,
  getCredentialRequests,
  getFilteredCredentialRequests,
  updateCredentialRequest,
  getDevicesList,
  checkDocumentStatus,
  revokeUserVerifiableCredential,
  resendEmailUserCredentialOrder,
  getCredentialRequest,
};
