import React, { useState } from 'react';
import { transformCamelCase } from '../helpers/StringHelpers';
import { renderChildren } from '../helpers/RenderFunctions';
import ArrowDown from '../../../../assets/arrow-down.svg';

interface CredentialDetailNestedTableProps {
  nestedKey: string;
  value: any;
  level: number;
}

const BACKGROUND_COLORS = [
  '#F5F8FB',
  '#E1E8EE',
  '#C6CFD9',
  '#B0BBC6',
  '#96A1AB',
];

export const CredentialDetailNestedTable = ({
  nestedKey,
  value,
  level,
}: CredentialDetailNestedTableProps) => {
  const isArrayOfObjects =
    !!nestedKey.match(/^[0-9]*$/) &&
    Object.prototype.toString.call(value) === '[object Object]';
  const isStringOrNumber =
    typeof value === 'string' || typeof value === 'number';

  const [isExpanded, setIsExpanded] = useState(
    isStringOrNumber || isArrayOfObjects,
  );
  const canExpand = !isStringOrNumber;

  return (
    <table
      className="nestedTable"
      style={{
        backgroundColor:
          nestedKey.match(/^[0-9]*$/) && level > 0
            ? 'white'
            : BACKGROUND_COLORS[level < 4 ? level : 4],
        boxShadow: level === 0 ? 'none' : '',
      }}
    >
      {!nestedKey.match(/^[0-9]*$/) && (
        <th scope="row">
          <button
            onClick={() => canExpand && setIsExpanded(!isExpanded)}
            className="nestedButton"
            style={{ cursor: canExpand ? 'pointer' : 'default' }}
          >
            <h3 className="nestedHeader">{transformCamelCase(nestedKey)}:</h3>
            {canExpand && (
              <img
                src={ArrowDown}
                alt="Expand arrow"
                style={{ transform: isExpanded ? '' : 'rotate(-90deg)' }}
              />
            )}
          </button>
        </th>
      )}
      {isExpanded && renderChildren(value, { level: level, isExpanded })}
    </table>
  );
};
