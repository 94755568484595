import React, { useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { EmailLogin } from './pages/EmailLogin/EmailLogin';
import { Callback } from './pages/Callback/Callback';
import { LoginCallback } from './pages/LoginCallback/LoginCallback';
import { CredentialsContextProvider } from './contexts/CredentialsContext';
import { Home } from './pages/Home/Home';
import { Credentials } from './pages/Credentials/Credentials';
import { UserCredentials } from './pages/UserCredentials/UserCredentials';
import { AuditTrails } from './pages/AuditTrails/AuditTrails';
import { AuditTrailsContextProvider } from './contexts/AuditTrailsContext';
import { UserCredentialsContextProvider } from './contexts/UserCredentialsContext';
import { AuthContext } from './contexts/AuthContext';

function App() {
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e: {
    preventDefault: () => void;
    returnValue: string;
  }) => {
    const now = new Date().getTime();
    const lastTime = Number(localStorage.getItem('last_refresh_token_request'));
    if (!lastTime || now - lastTime > 1000) {
      localStorage.setItem(
        'last_refresh_token_request',
        new Date().getTime().toString(),
      );
      return;
    }
    e.preventDefault();
    e.returnValue = '';
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <CredentialsContextProvider>
              <Home />
            </CredentialsContextProvider>
          }
        >
          <Route path="/credentials" element={<Credentials />} />
          <Route
            path="/user-credentials"
            element={
              <UserCredentialsContextProvider>
                <UserCredentials />
              </UserCredentialsContextProvider>
            }
          />
          <Route
            path="/audit-trails"
            element={
              <AuditTrailsContextProvider>
                <AuditTrails />
              </AuditTrailsContextProvider>
            }
          />
          <Route
            path="/"
            element={
              <Navigate to={accessToken ? '/credentials' : '/login'} replace />
            }
          />
        </Route>
        <Route
          path="/email-login/:credentialRequestId"
          element={<EmailLogin />}
        />
        <Route path="/callback" element={<Callback />} />
        <Route path="/login-callback" element={<LoginCallback />} />
        <Route path="*" element={<Navigate to="/credentials" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
