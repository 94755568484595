import { I18n } from '../i18n/i18n';

const FrontendErrorCodes = {
  LOGIN_AUTH: I18n.t('frontend_error_000'),
  CREDENTIAL_ORDER: I18n.t('frontend_error_001'),
  CREDENTIAL_LIST: I18n.t('frontend_error_002'),
  ISSUE_CREDENTIAL: I18n.t('frontend_error_003'),
  CREDENTIAL_TYPES: I18n.t('frontend_error_004'),
  LEVEL_OF_ASSURANCES: I18n.t('frontend_error_005'),
  LEVEL_OF_ASSURANCE_TYPE: I18n.t('frontend_error_006'),
  GET_ENTITIES: I18n.t('frontend_error_007'),
  RESEND_EMAIL: I18n.t('frontend_error_008'),
  CREDENTIAL_REQUEST: I18n.t('frontend_error_009'),
};

const FrontendErrors = {
  LOGIN_AUTH: FrontendErrorCodes.LOGIN_AUTH,
  CREDENTIAL_ORDER: FrontendErrorCodes.CREDENTIAL_ORDER,
  CREDENTIAL_LIST: FrontendErrorCodes.CREDENTIAL_LIST,
  ISSUE_CREDENTIAL: FrontendErrorCodes.ISSUE_CREDENTIAL,
  CREDENTIAL_TYPES: FrontendErrorCodes.CREDENTIAL_TYPES,
  LEVEL_OF_ASSURANCES: FrontendErrorCodes.LEVEL_OF_ASSURANCES,
  LEVEL_OF_ASSURANCE_TYPE: FrontendErrorCodes.LEVEL_OF_ASSURANCE_TYPE,
  GET_ENTITIES: FrontendErrorCodes.GET_ENTITIES,
  RESEND_EMAIL: FrontendErrorCodes.RESEND_EMAIL,
  CREDENTIAL_REQUEST: FrontendErrorCodes.CREDENTIAL_REQUEST,
};

export { FrontendErrors };
