import { useContext, useEffect } from 'react';
import { Container } from 'reactstrap';
import { AuditTrailsTable } from './AuditTrailsTable/AuditTrailsTable';
import FilterTag from '../../components/FilterTag/FilterTag';
import { AuditTrailsContext } from '../../contexts/AuditTrailsContext';
import { MenuContext } from '../../contexts/MenuContext';
import { AuditTrailsList } from '../../domain/auditTrails';
import { useRefreshToken } from '../../hooks/useRefreshToken';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';
import { getAuditTrails } from '../../services/vidcredentials';
import { SearchBar } from './SearchBar/SearchBar';
import refreshIcon from '../../assets/refreshIcon.svg';
import { Spinner } from '../../components/Spinner/Spinner';
import { I18n } from '../../i18n/i18n';

export const AuditTrails = () => {
  const {
    filters,
    setFilters,
    setAuditTrails,
    getAuditTrailsByFilters,
    loading,
    setLoading,
  } = useContext(AuditTrailsContext);

  const { searchableText, action, startDate, endDate, issuanceId } = filters;
  const { isMenuOpen } = useContext(MenuContext);
  useRouteSecurization(['admin']);

  const getInitialData = async () => {
    try {
      setLoading(true);
      const response = await getAuditTrails();
      setAuditTrails(response as AuditTrailsList[]);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    getAuditTrailsByFilters();
  };

  const tokenProcess = useRefreshToken(getInitialData);

  useEffect(() => {
    tokenProcess.refreshTokenProcess();
  }, []);

  return (
    <Container
      className={`credentials-container ${
        isMenuOpen ? 'open-lateral-menu' : ''
      }`}
      fluid
    >
      <h1>{I18n.t('menu.auditTrails')}</h1>
      <br></br>
      <SearchBar />
      <div className="filter-list-container">
        {searchableText && (
          <FilterTag
            filter={`${I18n.t('filters.searchText')}: ${searchableText}`}
            onClose={() => setFilters({ ...filters, searchableText: '' })}
          />
        )}
        {action && (
          <FilterTag
            filter={`${I18n.t('filters.actionName')}: ${I18n.t(
              `auditTrailsActions.${action}`,
            )}`}
            onClose={() => setFilters({ ...filters, action: '' })}
          />
        )}
        {startDate && (
          <FilterTag
            filter={`${I18n.t('filters.startDate')}: ${startDate}`}
            onClose={() => setFilters({ ...filters, startDate: '' })}
          />
        )}
        {endDate && (
          <FilterTag
            filter={`${I18n.t('filters.endDate')}: ${endDate}`}
            onClose={() => setFilters({ ...filters, endDate: '' })}
          />
        )}
        {issuanceId && (
          <FilterTag
            filter={`${I18n.t('details.credentialIssuanceId')}: ${issuanceId}`}
            onClose={() => setFilters({ ...filters, issuanceId: '' })}
          />
        )}
      </div>
      {loading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="refresh-icon-container">
            <img
              onClick={refreshData}
              src={refreshIcon}
              alt="Refresh icon"
              className="refresh-button"
            />
          </div>
          <AuditTrailsTable />
        </>
      )}
    </Container>
  );
};
