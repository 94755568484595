/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  ReactChild,
  useContext,
  useEffect,
  useState,
} from 'react';
import { AuditTrailsList } from '../domain/auditTrails';
import { getFilteredAuditTrails } from '../services/vidcredentials';
import { AuthContext } from './AuthContext';

interface AuditTrailsFilters {
  searchableText: string;
  action: string;
  startDate: string;
  endDate: string;
  entityName: string;
  issuanceId: string;
}

const INITIAL_FILTERS: AuditTrailsFilters = {
  searchableText: '',
  entityName: '',
  action: '',
  startDate: '',
  endDate: '',
  issuanceId: '',
};

const INITIAL_AUDIT_TRAILS: any[] = [];

export const AuditTrailsContext = createContext({
  loading: false,
  setLoading: (_loading: boolean) => {
    return;
  },
  filters: INITIAL_FILTERS,
  setFilters: (_filters: AuditTrailsFilters) => {
    return;
  },
  getAuditTrailsByFilters: () => {
    return;
  },
  auditTrails: INITIAL_AUDIT_TRAILS,
  setAuditTrails: (_auditTrails: AuditTrailsList[]) => {
    return;
  },
  advancedFilterModal: false,
  setAdvancedFilterModal: (_openModal: boolean) => {
    return;
  },
});

interface Props {
  children: ReactChild;
}

export const AuditTrailsContextProvider = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<AuditTrailsFilters>(INITIAL_FILTERS);
  const [auditTrails, setAuditTrails] = useState([] as AuditTrailsList[]);
  const [advancedFilterModal, setAdvancedFilterModal] = useState(false);
  const { accessToken } = useContext(AuthContext);

  const formatDateToISO = (dateString: string) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const dateISO = date.toISOString();

    return dateISO;
  };

  useEffect(() => {
    const areFiltersEmpty =
      !filters.action &&
      !filters.searchableText &&
      !filters.entityName &&
      !filters.startDate &&
      !filters.endDate &&
      !filters.issuanceId;

    if (advancedFilterModal || (areFiltersEmpty && !accessToken)) return;

    getAuditTrailsByFilters();
  }, [filters]);

  const getAuditTrailsByFilters = async () => {
    // iterate keys and values of the object and add them to the query string params

    const queryParams = Object.keys(filters)
      .map((key: string) => {
        if (key === 'searchableText')
          return `searchableText=${filters.searchableText}`;
        if (key === 'entityName') return `entityName=${filters.entityName}`;
        if (key === 'issuanceId') return `issuanceId=${filters.issuanceId}`;
        if (key === 'action') return `action=${filters.action}`;
        if (key === 'startDate')
          return `startDate=${formatDateToISO(filters.startDate)}`;
        if (key === 'endDate')
          return `endDate=${formatDateToISO(filters.endDate)}`;
      })
      .join('&');

    setLoading(true);
    const data = await getFilteredAuditTrails(queryParams);
    setLoading(false);
    setAuditTrails(data as any);
  };

  const { children } = props;

  const Provider = AuditTrailsContext.Provider;

  return (
    <Provider
      value={{
        loading,
        setLoading,
        filters,
        setFilters,
        getAuditTrailsByFilters,
        auditTrails,
        setAuditTrails,
        advancedFilterModal,
        setAdvancedFilterModal,
      }}
    >
      {children}
    </Provider>
  );
};
