import { FrontendErrors } from '../exceptions/frontendErrors';
import { BACKEND_URL, PATHS } from '../config';
import axiosInstance from './axiosInstance';

async function getIdentityVerificationMethods() {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.IDENTITY_VERIFICATION_METHOD}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(
        `${FrontendErrors.LEVEL_OF_ASSURANCES} ${response.data}.`,
      );
    }
    return response.data;
  } catch (error) {
    throw new Error(
      `${FrontendErrors.LEVEL_OF_ASSURANCES} ${(error as Error).message}.`,
    );
  }
}
export { getIdentityVerificationMethods as getLevelOfAssurances };
