/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  ReactChild,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CredentialType } from '../domain/credentialTypes';
import { UserCredentialListView } from '../domain/credentialView';
import {
  getCredentialTypes,
  getFilteredUserCredentials,
} from '../services/vidcredentials';
import { AuthContext } from './AuthContext';

interface UserCredentialsFilters {
  entityId: string;
  entityName: string;
  email: string;
  userName: string;
  did: string;
  searchableText: string;
  status: string;
}

const INITIAL_FILTERS: UserCredentialsFilters = {
  entityId: '',
  entityName: '',
  email: '',
  userName: '',
  did: '',
  searchableText: '',
  status: '',
};

const INITIAL_CREDENTIALS: UserCredentialListView[] = [];

const INITIAL_CREDENTIAL_TYPES: CredentialType[] = [];

export const UserCredentialsContext = createContext({
  loading: false,
  setLoading: (_loading: boolean) => {
    return;
  },
  filters: INITIAL_FILTERS,
  setFilters: (_filters: UserCredentialsFilters) => {
    return;
  },
  getCredentialsByFilters: () => {
    return;
  },
  credentials: INITIAL_CREDENTIALS,
  setCredentials: (_credentials: UserCredentialListView[]) => {
    return;
  },
  credentialTypes: INITIAL_CREDENTIAL_TYPES,
  loadSelectCredentialTypes: () => {
    return;
  },
  advancedFilterModal: false,
  setAdvancedFilterModal: (_openModal: boolean) => {
    return;
  },
});

interface Props {
  children: ReactChild;
}

export const UserCredentialsContextProvider = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] =
    useState<UserCredentialsFilters>(INITIAL_FILTERS);
  const [credentials, setCredentials] = useState(
    [] as UserCredentialListView[],
  );
  const [credentialTypes, setCredentialTypes] = useState([]);
  const [advancedFilterModal, setAdvancedFilterModal] = useState(false);
  const { accessToken } = useContext(AuthContext);

  useEffect(() => {
    const areFiltersEmpty =
      !filters.did &&
      !filters.email &&
      !filters.userName &&
      !filters.searchableText &&
      !filters.entityId &&
      !filters.status;

    if (advancedFilterModal || (areFiltersEmpty && !accessToken)) return;

    getCredentialsByFilters();
  }, [filters]);

  const getCredentialsByFilters = async () => {
    // iterate keys and values of the object and add them to the query string params

    const queryParams = Object.keys(filters)
      .map((key: string) => {
        if (key === 'email') return `email=${filters.email}`;
        if (key === 'userName') return `userName=${filters.userName}`;
        if (key === 'entityId') return `entityId=${filters.entityId}`;
        if (key === 'did') return `did=${filters.did}`;
        if (key === 'status') return `status=${filters.status}`;
        if (key === 'searchableText')
          return `searchableText=${filters.searchableText}`;
      })
      .join('&');

    setLoading(true);
    const data = await getFilteredUserCredentials(queryParams);
    setLoading(false);
    setCredentials(data as any);
  };

  const loadSelectCredentialTypes = () => {
    const loadCredentialTypes = async () => {
      try {
        const data: { id: string; type: string }[] = await getCredentialTypes();
        const parsedData =
          data?.map((item) => {
            return { id: item.id, name: item.type };
          }) || [];

        setCredentialTypes(parsedData as any);
        return parsedData;
      } catch (e) {
        console.log(e);
      }
    };

    loadCredentialTypes();
  };

  const { children } = props;

  const Provider = UserCredentialsContext.Provider;

  return (
    <Provider
      value={{
        loading,
        setLoading,
        filters,
        setFilters,
        getCredentialsByFilters,
        credentials,
        setCredentials,
        credentialTypes,
        loadSelectCredentialTypes,
        advancedFilterModal,
        setAdvancedFilterModal,
      }}
    >
      {children}
    </Provider>
  );
};
