import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { RefreshTokenResponseDto } from '../domain/refreshToken';
import axiosInstance from '../services/axiosInstance';
import { refreshToken } from '../services/vidcredentials';
import { UserAuth } from '../domain/userAuth';

export const useRefreshToken = (fetchData?: () => void) => {
  const { accessToken, setToken } = useContext(AuthContext);

  const location = useLocation();

  const refreshTokenProcess = async () => {
    if (!accessToken) {
      const refreshTokenResponse: RefreshTokenResponseDto =
        await refreshToken();

      const { access_token } = refreshTokenResponse;

      setToken(access_token);
      axiosInstance.defaults.headers.common['Authorization'] =
        'Bearer ' + access_token;

      const user = new UserAuth(accessToken);

      const isLogin = location.pathname === '/login';
      if (isLogin && access_token && !user.isSuperadmin())
        window.location.href = '/credentials';
      if (isLogin && access_token && user.isSuperadmin())
        window.location.href = '/user-credentials';

      if (access_token && fetchData) fetchData();
      return;
    }

    if (fetchData) fetchData();
  };

  return { refreshTokenProcess };
};
