import React, { useContext, useEffect } from 'react';
import './Login.css';
import { OpenIDClient } from '../../services/openIDClient';
import { AuthContext } from '../../contexts/AuthContext';
import { I18n } from '../../i18n/i18n';

import logo from './../../assets/camins-logo.svg';
import hero from './../../assets/login-background.svg';
import { LanguageSwitch } from '../../components/LanguageSwitch/LanguageSwitch';

export function Login() {
  const { setToken } = useContext(AuthContext);

  useEffect(() => {
    setToken('');
    const id = localStorage.getItem('intervalId');
    if (id) {
      clearInterval(id as string);
      localStorage.removeItem('intervalId');
    }
  }, []);

  const signInWithVIDchain = async () => {
    const client = OpenIDClient.getInstance().getLoginClient();
    client.wipeTokens();
    await client.callback();
    const token = await client.getToken({
      scopes: {
        request: ['openid', 'StudioCredential'],
      },
    });
    console.log('Token: ', token);
  };

  return (
    <>
      <div className="blue-login">
        <header>
          <span>
            <img src={logo} alt="Logo" />
          </span>
          <LanguageSwitch />
        </header>
        <section className="home-container">
          <div className="info-column">
            <h1>{I18n.t('login.control')}</h1>
            <div className="vid-chain-buttons-container">
              <button
                className="new-sign-in-vid-chain"
                onClick={() => signInWithVIDchain()}
              >
                <span>{I18n.t('login.login_with')}</span>
                <span>&rarr;</span>
              </button>
              {/* <a className="sign-in-vid-chain" href={'vidchain://'}>
                Click here
              </a> */}
            </div>
          </div>
          <div className="background-column">
            <img src={hero} alt="Background" />
          </div>
        </section>
      </div>
    </>
  );
}
