import React, { Fragment, useContext } from 'react';
import { Form, InputGroup } from 'reactstrap';
import { Input } from 'reactstrap';
import './SearchBar.css';
import advancedFilter from '../../../assets/advancedFilter.svg';
import searchIcon from '../../../assets/searchIcon.svg';
import { AdvancedFilterModal } from '../AdvancedFilterModal/AdvancedFilterModal';
import { AuditTrailsContext } from '../../../contexts/AuditTrailsContext';
import { I18n } from '../../../i18n/i18n';

export function SearchBar() {
  const { advancedFilterModal, setAdvancedFilterModal, setFilters, filters } =
    useContext(AuditTrailsContext);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <Fragment>
      {advancedFilterModal && <div className="black-layout"></div>}
      <Form
        onSubmit={handleSubmit}
        className={advancedFilterModal ? 'advanced-filter' : 'simple-filter'}
      >
        <div className="custom-searchbar">
          <InputGroup className="input-finder-search-bar">
            <img
              className="search-icon"
              src={searchIcon}
              onClick={() => setAdvancedFilterModal(true)}
            />
            <Input
              className="custom-searchbar-input"
              placeholder={I18n.t('actions.search')}
              value={filters.searchableText}
              onChange={(event) => {
                setFilters({
                  ...filters,
                  searchableText: event.target.value,
                });
              }}
            />{' '}
            <div>
              <img
                className="img-advanced-filter"
                src={advancedFilter}
                onClick={() => setAdvancedFilterModal(true)}
              />
            </div>
          </InputGroup>
        </div>

        {advancedFilterModal && (
          <AdvancedFilterModal
            isOpen={advancedFilterModal}
            setIsOpen={setAdvancedFilterModal}
          ></AdvancedFilterModal>
        )}
      </Form>
    </Fragment>
  );
}
