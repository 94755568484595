import React from 'react';
import './SuccessMessage.css';

interface Props {
  messageTitle: string;
  message: string;
}
export const SuccessMessage = (props: Props) => {
  return (
    <>
      <h1>{props.messageTitle}</h1>
      <p id="successMessage">{props.message}</p>
    </>
  );
};
