import React, { useContext, useEffect, useState } from 'react';

import './HeaderHome.css';
import { Avatar } from '../Avatar/Avatar';
import { AuthContext } from '../../contexts/AuthContext';
import { UserAuth } from '../../domain/userAuth';
import axiosInstance from '../../services/axiosInstance';
import { logout } from '../../services/vidcredentials';

import logoutIcon from './../../assets/logout.svg';
import TooltipItem from '../ToolTipItem/ToolTipItem';
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch';

export const HeaderHome = () => {
  const { accessToken, setToken } = useContext(AuthContext);
  const [userName, setUserName] = useState('-');
  const [entityName, setEntityName] = useState('-');
  const [role, setRole] = useState('');

  const setUserData = () => {
    const user = new UserAuth(accessToken);
    setUserName(user.getUserName());
    setEntityName(user.getEntityName());
    setRole(user.getRole());
  };

  const doLogout = async () => {
    try {
      await logout();
      setToken('');
      axiosInstance.defaults.headers.common['Authorization'] = '';
      window.location.href = '/login';
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (accessToken) {
      setUserData();
    }
  }, [accessToken]);

  return (
    <header className="headerHome">
      <LanguageSwitch />
      <Avatar userName={userName} entityName={entityName} role={role} />
      <TooltipItem id="logout" title="Log out">
        <img
          className="logout-icon"
          src={logoutIcon}
          alt="Logout Icon"
          onClick={() => doLogout()}
        />
      </TooltipItem>
    </header>
  );
};
