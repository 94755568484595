import React from 'react';

import logo from '../../assets/Camins_logo_C.png';
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch';

import './Header.css';

export const Header = () => {
  return (
    <nav className="loginHeader">
      <img src={logo} alt="Logo" />
      <LanguageSwitch />
    </nav>
  );
};
