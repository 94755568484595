export interface RefreshTokenRequestDto {
  grant_type: string;
}

export interface RefreshTokenResponseDto {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

export const TOKEN_REFRESH_TIME = 14.5 * 60 * 1000;
