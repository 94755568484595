import React from 'react';
import { formatDate, isIsoDate } from '../helpers/StringHelpers';

interface CredentialDetailTextProps {
  text: string;
}
export const CredentialDetailText = ({ text }: CredentialDetailTextProps) => {
  return (
    <td data-testid="payload" className="detailText">
      {isIsoDate(text) ? formatDate(text) : text}
    </td>
  );
};
