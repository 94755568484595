import React, { useContext, useEffect } from 'react';
import { Container } from 'reactstrap';
import './Credentials.css';
import { CredentialsTable } from './CredentialsTable/CredentialsTable';
import { SearchBar } from './SearchBar/SearchBar';
import { getCredentials } from '../../services/vidcredentials';
import { MenuContext } from '../../contexts/MenuContext';
import { CredentialListView } from '../../domain/credentialView';
import { CredentialsContext } from '../../contexts/CredentialsContext';
import FilterTag from '../../components/FilterTag/FilterTag';
import { useRefreshToken } from '../../hooks/useRefreshToken';
import refreshIcon from '../../assets/refreshIcon.svg';
import { Spinner } from '../../components/Spinner/Spinner';
import { I18n } from '../../i18n/i18n';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';

export function Credentials() {
  const {
    loading,
    setLoading,
    filters,
    setFilters,
    credentials,
    setCredentials,
    loadSelectCredentialTypes,
    getCredentialsByFilters,
  } = useContext(CredentialsContext);

  const { types, did, searchableText, status } = filters;

  const { isMenuOpen } = useContext(MenuContext);

  const getInitialData = () => {
    loadTable();
    loadSelectCredentialTypes();
  };
  useRouteSecurization(['admin', 'operator']);
  const tokenProcess = useRefreshToken(getInitialData);

  useEffect(() => {
    tokenProcess.refreshTokenProcess();
  }, []);

  const refreshData = async () => {
    getCredentialsByFilters();
  };

  const loadTable = () => {
    const loadCredentials = async () => {
      try {
        const data = await getCredentials();
        setCredentials(data as CredentialListView[]);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    setLoading(true);

    setTimeout(() => {
      loadCredentials();
    }, 300);
  };

  return (
    <Container
      className={`credentials-container ${
        isMenuOpen ? 'open-lateral-menu' : ''
      }`}
      fluid
    >
      <h1>{I18n.t('menu.credentials')}</h1>
      <br></br>
      <SearchBar />
      <div className="filter-list-container">
        {status && (
          <FilterTag
            filter={`${I18n.t('table.status')}: ${I18n.t(`status.${status}`)}`}
            onClose={() => setFilters({ ...filters, status: '' })}
          />
        )}
        {types && (
          <FilterTag
            filter={`${I18n.t('details.type')}: ${types}`}
            onClose={() => setFilters({ ...filters, types: '' })}
          />
        )}
        {did && (
          <FilterTag
            filter={`DID: ${did}`}
            onClose={() => setFilters({ ...filters, did: '' })}
          />
        )}
        {searchableText && (
          <FilterTag
            filter={`${I18n.t('filters.searchText')}: ${searchableText}`}
            onClose={() => setFilters({ ...filters, searchableText: '' })}
          />
        )}
      </div>
      {loading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="refresh-icon-container">
            <img
              onClick={refreshData}
              src={refreshIcon}
              alt="Refresh icon"
              className="refresh-button"
            />
          </div>
          <CredentialsTable credentialsList={credentials} />
        </>
      )}
    </Container>
  );
}
