import React, { useContext } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

import logo from '../../assets/camins-logo.svg';
import credentialManagement from '../../assets/credentialManagement.png';
import credentialManagementSelected from '../../assets/credentialsManagementSelected.png';
import roleManagement from '../../assets/roleManagement.png';
import roleManagementSelected from '../../assets/roleManagementSelected.png';
import credentialProfile from '../../assets/credentialProfile.png';
import credentialProfileSelected from '../../assets/credentialProfileSelected.png';
import './LateralMenu.css';
import { MenuContext } from '../../contexts/MenuContext';
import { UserAuth } from '../../domain/userAuth';
import { AuthContext } from '../../contexts/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { I18n } from '../../i18n/i18n';

export const LateralMenu = () => {
  const location = useLocation();
  const { isMenuOpen, setIsOpen } = useContext(MenuContext);
  const { accessToken } = useContext(AuthContext);

  let isAdmin = false;
  let isSuperadmin = false;

  if (accessToken) {
    const user = new UserAuth(accessToken);

    isAdmin = user.isAdmin();
    isSuperadmin = user.isSuperadmin();
  }

  return (
    <div className={`navDiv ${!isMenuOpen ? 'moved' : ''}`}>
      {isMenuOpen && <img src={logo} className="logoImage" />}
      <Nav vertical className="navLinks">
        {!isSuperadmin && (
          <NavItem>
            <NavLink
              className={
                location.pathname === '/credentials'
                  ? 'navlinkSelected'
                  : 'navlink'
              }
            >
              <Link to={'/credentials'}>
                <>
                  <img
                    src={
                      location.pathname === '/credentials'
                        ? credentialManagementSelected
                        : credentialManagement
                    }
                    className="iconLateral"
                  />
                  {isMenuOpen && I18n.t('menu.credentials')}
                </>
              </Link>
            </NavLink>
          </NavItem>
        )}
        {(isSuperadmin || isAdmin) && (
          <NavItem>
            <NavLink
              className={
                location.pathname === '/user-credentials'
                  ? 'navlinkSelected'
                  : 'navlink'
              }
            >
              <Link to={'/user-credentials'}>
                <>
                  {' '}
                  <img
                    src={
                      location.pathname === '/user-credentials'
                        ? roleManagementSelected
                        : roleManagement
                    }
                    className="iconLateral"
                  />
                  {isMenuOpen && I18n.t('menu.user')}
                </>
              </Link>
            </NavLink>
          </NavItem>
        )}
        {isAdmin && (
          <NavItem>
            <NavLink
              className={
                location.pathname === '/audit-trails'
                  ? 'navlinkSelected'
                  : 'navlink'
              }
            >
              <Link to={'/audit-trails'}>
                <>
                  {' '}
                  <img
                    src={
                      location.pathname === '/audit-trails'
                        ? credentialProfileSelected
                        : credentialProfile
                    }
                    className="iconLateral"
                  />
                  {isMenuOpen && I18n.t('menu.auditTrails')}
                </>
              </Link>
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <button
        className={`toggler ${!isMenuOpen ? 'open-toggler' : ''}`}
        onClick={() => setIsOpen(!isMenuOpen)}
      >
        {isMenuOpen ? '<<' : '>>'}
      </button>
    </div>
  );
};
