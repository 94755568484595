import React from 'react';
import { CredentialDetailNestedTable } from './CredentialDetailNestedTable';

interface CredentialDetailListProps {
  credentialDetail: any;
  level: number;
}

export const CredentialDetailList = ({
  credentialDetail,
  level,
}: CredentialDetailListProps) => {
  return (
    <>
      <tr />
      {Object.entries(credentialDetail).map(([key, value]) => {
        return (
          <CredentialDetailNestedTable
            key={key}
            nestedKey={key}
            value={value}
            level={level}
          />
        );
      })}
    </>
  );
};
