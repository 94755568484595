import { FrontendErrors } from '../exceptions/frontendErrors';
import { BACKEND_URL, PATHS } from '../config';
import axiosInstance from './axiosInstance';

async function getEntities() {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.ENTITY_LIST}`,
    );
    if (response.status !== 200) {
      throw new Error(`${FrontendErrors.GET_ENTITIES} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    throw new Error(
      `${FrontendErrors.GET_ENTITIES} ${(error as Error).message}.`,
    );
  }
}
export { getEntities };
