import './CredentialsTable.css';
import {
  getCredentialView,
  getCredentials,
  ParsedBackendError,
  revokeVerifiableCredential,
} from '../../../services/vidcredentials';
import { CredentialDetailsModal } from '../CredentialDetailsModal/CredentialDetailsModal';
import { useContext, useState } from 'react';
import {
  CredentialListView,
  CredentialOrderDetailsView,
} from '../../../domain/credentialView';
import eyeSelectedIcon from '../../../assets/eyeSelectedIcon.png';
import revokeIcon from '../../../assets/revokeIcon.png';
import TooltipItem from '../../../components/ToolTipItem/ToolTipItem';

import MaterialTable from 'material-table';
import { CredentialsContext } from '../../../contexts/CredentialsContext';
import { Popup } from '../../../components/Popup/Popup';
import { I18n } from '../../../i18n/i18n';

interface Props {
  credentialsList: CredentialListView[];
}

export function CredentialsTable(props: Props) {
  const [isCredentialDetailsModalOpen, setCredentialDetailsModalOpen] =
    useState(false);
  const { setCredentials } = useContext(CredentialsContext);

  const [credential, setCredential] = useState(
    {} as CredentialOrderDetailsView,
  );
  const [projection, setProjection] = useState({} as CredentialListView);
  const [requestedRevocation, setRequestedRevocation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [credentialToRevoke, setCredentialToRevoke] = useState<string | null>(
    null,
  );

  const loadTable = () => {
    const loadCredentials = async () => {
      const data = await getCredentials();
      setCredentials(data as CredentialListView[]);
      return data;
    };
    loadCredentials();
  };

  const openCredential = async (data: CredentialListView, e: any) => {
    e.preventDefault(); //Prevent the default href=# behavoir
    try {
      const credential = await getCredentialView(data.detailsURL);
      setCredential(credential as CredentialOrderDetailsView);
      setProjection(data);
      setCredentialDetailsModalOpen(true);
    } catch (error) {
      console.log('Error unable to get the credentialDetails');
      return;
    }
  };
  const revokeCredential = async (credentialId: string) => {
    try {
      setRequestedRevocation(true);
      await revokeVerifiableCredential(credentialId);
      loadTable();
      setTimeout(loadTable, 5000);
      setSuccessMessage(I18n.t('details.revokeSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }

    setCredentialToRevoke(null);
  };

  const formatDate = (date: string | undefined): string => {
    if (!date) {
      return I18n.t('table.notAvailable');
    }
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString();
  };

  const tableColumns = [
    {
      title: 'DID',
      field: 'did',
      render: (data: CredentialListView) => {
        return (
          <>
            {!['CREATED', 'VERIFIED', 'UNVERIFIED'].includes(data.status) ? (
              <TooltipItem id={'did' + data.issuanceId} title={data.did}>
                <div className="divFixed">
                  <span>{data.did}</span>
                </div>
              </TooltipItem>
            ) : (
              I18n.t('table.notAvailable')
            )}
          </>
        );
      },
    },
    {
      title: I18n.t('filters.credentialType'),
      field: 'credentialType',
    },
    {
      title: I18n.t('table.creationDate'),
      field: 'creationDate',
      render: (data: CredentialListView) => formatDate(data.creationDate),
    },
    {
      title: I18n.t('table.status'),
      field: 'status',
      render: (data: CredentialListView) => I18n.t(`status.${data.status}`),
    },
    {
      title: '',
      field: '',
      render: (data: CredentialListView) => {
        return (
          <div className="icons-container">
            <button
              onClick={() =>
                setCredentialToRevoke(
                  data.revokeURL
                    ? data.revokeURL.split('/').slice(-1).join()
                    : null,
                )
              }
              disabled={data.status !== 'ISSUED' || requestedRevocation}
              className="imageButton"
            >
              <TooltipItem
                id={'revoke' + data.issuanceId}
                title={I18n.t('table.revokeCredential')}
                enabled={data.status === 'ISSUED'}
              >
                <img src={revokeIcon} />
              </TooltipItem>
            </button>{' '}
            <button
              className="imageButton"
              onClick={(e) => openCredential(data, e)}
            >
              <TooltipItem
                id={'detail' + data.issuanceId}
                title={I18n.t('table.viewDetails')}
              >
                <img src={eyeSelectedIcon} className="eyeIcon" />
              </TooltipItem>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <CredentialDetailsModal
        isOpen={isCredentialDetailsModalOpen}
        setIsOpen={setCredentialDetailsModalOpen}
        credential={credential}
        projection={projection}
      />
      <MaterialTable
        columns={tableColumns}
        data={props.credentialsList}
        title={''}
        options={{
          selection: false,
          search: false,
          pageSize: 10,
          headerStyle: {
            background: '#F9F9FB 0% 0% no-repeat padding-box',
            borderRadius: '6px 6px 0px 0px',
            opacity: 1,
            fontFamily: 'TT-Norms-Black',
          },
        }}
      />
      {errorMessage && (
        <Popup
          error
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      {successMessage && (
        <Popup
          success
          message={successMessage}
          onClose={() => setSuccessMessage('')}
        />
      )}
      {credentialToRevoke && (
        <Popup
          confirmation
          message={I18n.t('table.sureRevoke')}
          onConfirm={() => revokeCredential(credentialToRevoke)}
          onReject={() => setCredentialToRevoke(null)}
          onClose={() => setCredentialToRevoke(null)}
        />
      )}
    </div>
  );
}
