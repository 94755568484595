import React, { useContext, useState } from 'react';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import {
  CredentialListView,
  CredentialOrderDetailsView,
  UserCredentialListView,
} from '../../../../domain/credentialView';
import '../CredentialDetailsModal.css';
import closeIcon from '../../../../assets/closeIcon.png';
import {
  getCredentials,
  ParsedBackendError,
  resendEmailCredentialOrder,
} from '../../../../services/vidcredentials';
import { Popup } from '../../../../components/Popup/Popup';
import { CredentialsContext } from '../../../../contexts/CredentialsContext';
import { I18n } from '../../../../i18n/i18n';
import { UserCredentialsContext } from '../../../../contexts/UserCredentialsContext';
import { renderChildren } from '../helpers/RenderFunctions';
import { formatDate, transformCamelCase } from '../helpers/StringHelpers';

interface Props {
  credential: CredentialOrderDetailsView;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const CredentialOrderModal = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { credentials, setCredentials } = useContext(CredentialsContext);
  const userCredentials = useContext(UserCredentialsContext).credentials;

  const loadTable = () => {
    const loadCredentials = async () => {
      const data = await getCredentials();
      setCredentials(data as CredentialListView[]);
      return data;
    };
    loadCredentials();
  };

  const closeModal = (e: any) => {
    e.preventDefault();
    props.setIsOpen(false);
  };

  const getCredential = (
    issuanceId: string,
  ): CredentialListView | undefined => {
    let credentialListView:
      | CredentialListView[]
      | UserCredentialListView[]
      | undefined = undefined;
    credentialListView = credentials;
    if (
      props.credential.types.some(
        (type) => type.toUpperCase() === 'VIDCREDENTIALSTUDIO',
      )
    )
      credentialListView = userCredentials;
    return credentialListView.find(
      (credential) => credential.issuanceId === issuanceId,
    );
  };
  const resendEmail = async () => {
    try {
      const credentialView = getCredential(props.credential.issuanceId);
      if (!credentialView) {
        console.log('Could not find credential');
        return;
      }
      await resendEmailCredentialOrder(credentialView.resendURL ?? '');
      loadTable();
      setSuccessMessage(I18n.t('details.emailResentSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }
  };

  return (
    <Modal isOpen={props.isOpen} centered={true} fullscreen="lg" size="lg">
      <ModalHeader
        close={
          <a href="#" onClick={(e) => closeModal(e)}>
            <img src={closeIcon} className="iconClose" />
          </a>
        }
        toggle={() => props.setIsOpen(false)}
        className="modalHeader"
      >
        {props.credential.types?.includes('StudioCredential')
          ? I18n.t('details.userDetails')
          : I18n.t('details.credentialDetails')}
      </ModalHeader>
      <ModalBody className="modalBody">
        <table className="tableModal">
          <thead>
            <tr>
              <td style={{ backgroundColor: 'white' }}>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{I18n.t('details.credentialIssuanceId')}:</th>
              <td data-testid="type">{props.credential.issuanceId}</td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('details.type')}:</th>
              <td data-testid="type">{props.credential.types}</td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('details.issuer')}:</th>
              <td>{props.credential.issuer}</td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('details.holder')}:</th>
              <td data-testid="holder">{props.credential.holder}</td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('table.status')}:</th>
              <td data-testid="status">
                {I18n.t(`status.${props.credential.status}`)}
              </td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('table.ivmStatus')}:</th>
              <td data-testid="ivm-status">
                {I18n.t(
                  `identityVerificationStatus.${props.credential.identityVerificationMethodStatus}`,
                )}
              </td>
            </tr>
            <tr>
              <th scope="row">{I18n.t('details.createdAt')}:</th>
              <td data-testid="created-at">
                {formatDate(props.credential.createdAt)}
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'white' }}>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        {props.credential.payload &&
          Object.entries(props.credential.payload).map(([key, value]) => {
            return (
              <div key={key}>
                <h2 className="attributeHeader">{transformCamelCase(key)}</h2>
                {renderChildren(value, {})}
                <tr>&nbsp;</tr>
              </div>
            );
          })}
      </ModalBody>
      <ModalFooter>
        <div className="buttons-container">
          {props.credential.status === 'VERIFIED' && (
            <>
              <Button className="buttonResend" onClick={resendEmail}>
                {I18n.t('actions.resend')}
              </Button>{' '}
            </>
          )}
        </div>
        {errorMessage && (
          <Popup
            error
            message={errorMessage}
            onClose={() => setErrorMessage('')}
          />
        )}
        {successMessage && (
          <Popup
            success
            message={successMessage}
            onClose={() => setSuccessMessage('')}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
