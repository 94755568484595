import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, InputGroup } from 'reactstrap';
import { AuditTrailsContext } from '../../../contexts/AuditTrailsContext';
import { AuthContext } from '../../../contexts/AuthContext';
import './AdvancedFilterModal.css';
import { UserAuth } from './../../../domain/userAuth';
import { getEntities } from '../../../services/entities';
import { Entity } from '../../../domain/entity';

import { I18n } from '../../../i18n/i18n';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function AdvancedFilterModal(props: Props) {
  const { filters, setFilters, getAuditTrailsByFilters } =
    useContext(AuditTrailsContext);
  const [entities, setEntities] = useState<Entity[]>([]);

  const { entityName, action, startDate, endDate, issuanceId } = filters;

  const { accessToken } = useContext(AuthContext);

  let isSuperadmin = false;

  if (accessToken) {
    const user = new UserAuth(accessToken);

    isSuperadmin = user.isSuperadmin();
  }

  const handleClose = () => {
    setFilters({
      entityName: '',
      action: '',
      issuanceId: '',
      searchableText: '',
      startDate: '',
      endDate: '',
    });
    props.setIsOpen(false);
  };

  const applyFilter = () => {
    getAuditTrailsByFilters();
    props.setIsOpen(false);
  };

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;

    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const fetchEntities = async () => {
    const user = new UserAuth(accessToken);

    isSuperadmin = user.isSuperadmin();

    if (isSuperadmin) {
      const entitiesResponse = await getEntities();

      setEntities(entitiesResponse as Entity[]);
    }
  };

  useEffect(() => {
    fetchEntities();
  }, []);

  return (
    <>
      {isSuperadmin && (
        <InputGroup className="input-finder-advanced-search-bar">
          <Input
            placeholder={I18n.t('filters.entityName')}
            name="entityName"
            value={entityName}
            onChange={handleChange}
            type="select"
          >
            <option>{I18n.t('filters.selectEntity')}</option>
            {entities.map((entity: Entity, i: number) => {
              return (
                <option key={i} value={entity.name}>
                  {entity.name}
                </option>
              );
            })}
          </Input>
        </InputGroup>
      )}
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          id="exampleSelect"
          placeholder={I18n.t('filters.actionName')}
          name="action"
          value={action}
          type="select"
          onChange={handleChange}
        >
          <option value="">-- {I18n.t('filters.actionName')} --</option>
          <option value="UserLogin">
            {I18n.t('auditTrailsActions.UserLogin')}
          </option>
          <option value="CredentialOrderCreated">
            {I18n.t('auditTrailsActions.CredentialOrderCreated')}
          </option>
          <option value="ResendCredentialOrderEmail">
            {I18n.t('auditTrailsActions.ResendCredentialOrderEmail')}
          </option>
          <option value="CredentialHolderAuthenticated">
            {I18n.t('auditTrailsActions.CredentialHolderAuthenticated')}
          </option>
          <option value="CredentialIssued">
            {I18n.t('auditTrailsActions.CredentialIssued')}
          </option>
          <option value="CredentialRevocationRequested">
            {I18n.t('auditTrailsActions.CredentialRevocationRequested')}
          </option>
          <option value="CredentialRevoked">
            {I18n.t('auditTrailsActions.CredentialRevoked')}
          </option>
          <option value="CredentialOrderRequestCreated">
            {I18n.t('auditTrailsActions.CredentialRequestCreated')}
          </option>
          <option value="CredentialRequestConfirmed">
            {I18n.t('auditTrailsActions.CredentialRequestConfirmed')}
          </option>
          <option value="CredentialRequestDocumentSigned">
            {I18n.t('auditTrailsActions.CredentialRequestDocumentSigned')}
          </option>
        </Input>
      </InputGroup>
      <InputGroup className="input-finder-advanced-search-bar">
        <Input
          placeholder="Issuance ID"
          name="issuanceId"
          value={issuanceId}
          onChange={handleChange}
          type="text"
        ></Input>
      </InputGroup>
      <InputGroup className="datepickers-container">
        <div className="input-datepicker-advanced-search-bar">
          <label>{I18n.t('filters.startDate')}</label>
          <Input
            type="datetime-local"
            placeholder={I18n.t('filters.startDate')}
            name="startDate"
            value={startDate}
            onChange={handleChange}
          />{' '}
        </div>
        <div className="input-datepicker-advanced-search-bar">
          <label>{I18n.t('filters.endDate')}</label>
          <Input
            type="datetime-local"
            placeholder={I18n.t('filters.endDate')}
            name="endDate"
            value={endDate}
            onChange={handleChange}
          />{' '}
        </div>
      </InputGroup>
      <Button
        className="buttonCancel"
        variant="secondary"
        onClick={handleClose}
      >
        {I18n.t('actions.cancel')}
      </Button>
      <Button
        className="buttonFilter"
        variant="secondary"
        onClick={applyFilter}
      >
        {I18n.t('actions.filter')}
      </Button>
    </>
  );
}
